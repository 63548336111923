// https://blog.nimbleways.com/interantionalization-in-react-i18next/
import { use } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import _ from 'lodash'
import {
  initReactI18next,
  Namespace,
  useTranslation,
  UseTranslationOptions,
  UseTranslationResponse
} from 'react-i18next'

import { namespaces } from '@/assets/i18n'

import {
  I18nResource,
  LoadedResources,
  SupportedLocale,
  SupportedNamespace,
  TFunctionParams,
  Translation,
  TranslationKey,
  Translations,
  TypedTranslationOptions,
  UseTypedTranslationResponse
} from './types'

const adaptLoadedResources = (): I18nResource => {
  const flatNamespaces = Object.entries(namespaces) as [SupportedNamespace, LoadedResources][]
  const flatResources = flatNamespaces.map((namespace) => {
    const locales = Object.entries(namespace[1]) as [SupportedLocale, Translation][]
    return locales.reduce<I18nResource>((accumulator, locale) => {
      accumulator[locale[0]] = {
        [`${namespace[0]}`]: locale[1]
      } as Translations
      return accumulator
    }, {} as I18nResource)
  })

  return _.spread(_.partial(_.merge, {}))(flatResources)
}

const defaultNamespace: SupportedNamespace = 'common'
const defaultLanguage: SupportedLocale = 'fr'
const keySeparator = '.'

const resources: I18nResource = adaptLoadedResources()
const namespaceNames = Object.keys(namespaces) as SupportedNamespace[]

const namespacesRecord: Record<SupportedNamespace, SupportedNamespace> = namespaceNames.reduce(
  (record, ns) => Object.assign(record, { [ns]: ns }),
  {} as Record<SupportedNamespace, SupportedNamespace>
)

const useTypedTranslation = <N extends Namespace>(
  ns?: N,
  options?: UseTranslationOptions
): UseTypedTranslationResponse<N> => {
  const response: UseTranslationResponse<SupportedNamespace, undefined> = useTranslation(
    ns,
    options
  )

  function t(
    key: TranslationKey,
    options?: TypedTranslationOptions,
    defaultValue?: TFunctionParams<N>[1]
  ) {
    return response.t(key, defaultValue, options)
  }

  return { ...response, t }
}

const initI18n = (locale?: string): void => {
  use(initReactI18next)
    .use(LanguageDetector)
    .init({
      ns: namespaceNames,
      defaultNS: defaultNamespace,
      lng: locale,
      resources,
      compatibilityJSON: 'v3',
      fallbackLng: defaultLanguage,
      keySeparator: keySeparator,
      // TECHNICAL DEBT : used to avoid character codes in texts -> user values have to be escaped manually to mitigate XSS attacks
      interpolation: { escapeValue: false },
      react: { useSuspense: false }
    })
}

export { keySeparator, namespacesRecord as namespaces }
export { useTypedTranslation, initI18n }
