import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'

import styles from './styles'

const CustomizedButton = styled(Button)(styles(12))

const IbButton = () => {
  return (
    <CustomizedButton>
      My button <div className='red'>Red</div>
    </CustomizedButton>
  )
}

export { IbButton, IbButton as default }
