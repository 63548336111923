import logo from '@/assets/logo/logo.svg'
import { Counter } from '@/features/counter/Counter'
import { namespaces } from '@/services/i18n'

import { IbButton, IbTrans } from '@/components'
import './App.css'

function App() {
  return (
    <div className='App'>
      <header className='App-header'>
        <IbButton></IbButton>
        <IbTrans
          i18nKey='signin.header'
          ns={namespaces.auth}
          components={{
            Link: <a href='https://github.com/Ar-mane/typed-i18next' />
          }}
        />
        <IbTrans
          i18nKey='signin.term&policy'
          ns={namespaces.auth}
          components={{
            signed: (
              <a
                style={{ textDecoration: 'underline' }}
                href='https://github.com/Ar-mane/typed-i18next'
              />
            ),
            click: <div className='bold' />
          }}
        />
        <img
          src={logo}
          className='App-logo'
          alt='logo'
        />
        <Counter />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <span>
          <span>Learn </span>
          <a
            className='App-link'
            href='https://reactjs.org/'
            target='_blank'
            rel='noopener noreferrer'
          >
            React
          </a>
          <span>, </span>
          <a
            className='App-link'
            href='https://redux.js.org/'
            target='_blank'
            rel='noopener noreferrer'
          >
            Redux
          </a>
          <span>, </span>
          <a
            className='App-link'
            href='https://redux-toolkit.js.org/'
            target='_blank'
            rel='noopener noreferrer'
          >
            Redux Toolkit
          </a>
          ,<span> and </span>
          <a
            className='App-link'
            href='https://react-redux.js.org/'
            target='_blank'
            rel='noopener noreferrer'
          >
            React Redux
          </a>
        </span>
      </header>
    </div>
  )
}

export default App
