import { Trans } from 'react-i18next'

import { IbTransProps } from './types'

const IbTrans = ({ components, i18nKey, ns, prefix }: IbTransProps) => {
  return (
    <Trans
      components={components}
      i18nKey={i18nKey}
      ns={ns}
      prefix={prefix}
    />
  )
}

export { IbTrans, IbTrans as default }
