export default (borderRadius: number) => ({
  color: 'yellow',
  backgroundColor: 'green',
  padding: 48,
  borderRadius: borderRadius,
  '&:hover': {
    backgroundColor: 'purple'
  },
  '.red': {
    color: 'red'
  }
})
